import { graphql } from 'gatsby';
import { object } from 'prop-types';
import { StaticPagesTemplate } from '../../components/common/static-pages-template';

function CastleRockAlimonyLawyer({ data }) {
  return (
    <StaticPagesTemplate data={data} />
  );
}

export const query = graphql`
  {
    wpPage(uri: {eq: "/castle-rock-alimony-lawyer/"}) {
      content
      title
      uri
      ...SeoPage
    }
  }
`;

CastleRockAlimonyLawyer.propTypes = {
  data: object,
};

CastleRockAlimonyLawyer.defaultProps = {
  data: {},
};

export default CastleRockAlimonyLawyer;
export { Head } from '../../components/seo/seo';
